import { ExplorePreferences } from '@citruscamps/citrus-client'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { GetServerSideProps, GetServerSidePropsContext, NextPage, PreviewData } from 'next'
import Head from 'next/head'
import { ParsedUrlQuery } from 'querystring'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import { CitrusHeaderNavigation } from '../components/CitrusHeaderNavigation/CitrusHeaderNavigation'
import { ExploreHome } from '../components/ExploreHome/ExploreHome'
import { ExploreHomeHeader } from '../components/ExploreHome/components/ExploreHomeHeader'
import { Footer } from '../components/Footer/Footer'
import { Meta } from '../components/Meta/Meta'
import { Toast } from '../components/Toast/Toast'
import { THEME_COLOR } from '../constants/website'
import { useToast } from '../hooks/useToast'
import { track } from '../server/track'
import { toQueryParams } from '../utils/query-params'
const parseHeader = (header: string | string[] | undefined): string | undefined =>
  Array.isArray(header) ? header[0] : header

interface IProps {
  clientIp: string | null
  pathname: string
  hostname: string
}

const HomePage: NextPage<IProps> = ({ pathname, hostname, clientIp }: IProps) => {
  const preferences: ExplorePreferences = {
    version: 2,
    brand_accent_color: THEME_COLOR,
  }
  const { toast } = useToast()
  const q = toQueryParams({
    brand_accent_color: preferences?.brand_accent_color,
  })
  const title = process.env.REACT_APP_WEBSITE_NAME || 'Citrus'
  return (
    <ThemeProvider theme={preferences as DefaultTheme}>
      <Meta
        themeColor={preferences?.brand_accent_color}
        title={[title, 'Featured Businesses'].filter((t) => !!t).join(' · ')}
        url={pathname}
      />
      <Head>
        <link href={`/api/styles/branding.css?${q}`} rel="stylesheet" />
      </Head>
      {toast ? <Toast {...toast} /> : null}
      <div className="custom-branding">
        <main>
          <CitrusHeaderNavigation hostname={hostname} />
          <ExploreHomeHeader hostname={hostname} />
          <ExploreHome clientIp={clientIp || undefined} />
        </main>
        <Footer theme="light" />
      </div>
    </ThemeProvider>
  )
}

export const getServerSideProps: GetServerSideProps = async (
  ctx: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>,
) => {
  const { req, resolvedUrl } = ctx
  const host =
    (req ? req.headers['x-forwarded-host'] || req.headers['host'] : window.location.host) || ''
  const protocol = host.indexOf('localhost') > -1 ? 'http' : 'https'
  await track({
    eventName: 'view_page',
    context: {
      request: ctx.req,
      url: `${protocol}://${host}${resolvedUrl}`,
    },
  })
  const xRealIp = parseHeader(req?.headers?.['x-real-ip'])
  const xForwardedFor = parseHeader(req?.headers?.['x-forwarded-for'])
  const clientIp = xRealIp || xForwardedFor || req.socket?.remoteAddress
  return {
    props: {
      clientIp: clientIp !== '::1' && clientIp ? clientIp : null,
      pathname: `${protocol}://${host}${resolvedUrl}`,
      hostname: `${protocol}://${host}`,
    },
  }
}

export default HomePage
