import { faSearch, faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef } from 'react'
import { Venue } from '@citruscamps/citrus-client'
import { Address } from '../../../utils/address'
import { findMatchVenue } from '../../../utils/findMatchVenue'
import { useFetchLocations } from '../hooks/useFetchLocations'
import { VenueSearch } from './VenueSearch'

interface IProps {
  view: 'list' | 'map'
  source?: 'ip_address' | 'geolocation'
  search?: string
  address?: Address
  isLoading?: boolean
  onChangeSearch: (search: string) => Promise<void> | void
  onSelectLocation: (value: Venue) => void
  onChangeView: (value: 'list' | 'map') => void
  onRequestLocation: () => void | Promise<void>
}

export const HomeSearchBar = ({
  view,
  source,
  search = '',
  address,
  isLoading,
  onChangeSearch: handleChangeSearch,
  onSelectLocation: handleSelectLocation,
  onChangeView: handleChangeView,
  onRequestLocation: handleRequestLocation,
}: IProps) => {
  const searchInputRef = useRef<HTMLInputElement>(null)
  const { data: venues, isLoading: isLoadingVenues, handleSearchLocations } = useFetchLocations({})
  const userVenue = findMatchVenue(address?.city, venues)
  return (
    <>
      <div className="row mt-4 mb-3">
        <div className="col-12 col-md-9 col-lg-10">
          <h2 className="d-inline-block" style={{ verticalAlign: 'middle' }}>
            Explore youth programs in
            <span className="sr-only">{address?.city || userVenue?.city}</span>
          </h2>
          <div
            className="mt-0 mb-2 mb-lg-auto pl-lg-3 py-1 d-inline-block"
            style={{ verticalAlign: 'middle' }}
          >
            {isLoadingVenues || isLoading ? (
              <div className="fa-xl">
                <FontAwesomeIcon icon={faSpinner} pulse fixedWidth={true} />
              </div>
            ) : (
              <VenueSearch
                instanceId="venue-select"
                venue={address?.toVenue() || userVenue}
                venues={venues}
                source={source}
                onChange={(value?: Venue) => {
                  if (value) {
                    handleSelectLocation(value)
                  }
                }}
                onSearchVenues={async (search: string) => await handleSearchLocations(search)}
                onRequestLocation={handleRequestLocation}
              />
            )}
          </div>
        </div>
        <div className="col-12 col-md-3 col-lg-2 text-md-right text-center">
          <div className="btn-group" role="group">
            <button
              type="button"
              className={`btn ${view === 'list' ? 'btn-primary' : 'btn-outline-primary'}`}
              onClick={() => handleChangeView('list')}
            >
              <span className="px-2">List</span>
            </button>
            <button
              type="button"
              className={`btn ${view === 'map' ? 'btn-primary' : 'btn-outline-primary'}`}
              onClick={() => handleChangeView('map')}
            >
              <span className="px-2">Map</span>
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex mt-3 mb-4">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text bg-white border-right-0">
              <FontAwesomeIcon icon={faSearch} className="mr-1" />
            </span>
          </div>
          <input
            ref={searchInputRef}
            type="text"
            className="form-control form-control-lg mr-3 border-left-0"
            placeholder="Search for programs or events..."
            aria-label="Search for programs or events..."
            aria-describedby="search"
            value={search}
            onChange={async (e) => await handleChangeSearch(e.target.value)}
          />
        </div>
      </div>
    </>
  )
}
